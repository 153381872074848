$baseFontColor: #5a5c5b;
$colorRed: #eb1315;
$colorDarkRed: #c90002;
$colorWhite: #ffffff;
$colorGray: #d3ccc3;
$colorBlack: #000000;
$colorBlackAlpha: rgba(0, 0, 0, 0.7);

.color-white {
    color: $colorWhite;
}
.color-gray {
    color: $colorGray;
}
.color-red {
    color: $colorRed;
}
.color-dark-red {
    color: $colorDarkRed;
}
