.pull-left {
    float: left;
}
.pull-right {
    float: right;
}

.clearfix {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

.hidden {
    display: none;
}

.text-center {
    text-align: center;
}
.text-right {
    text-align: right
}
