a {
    transition: all 0.5s ease;
    color: $baseFontColor;
    cursor: pointer;
}
p {
    font-size: 1.1em;
    line-height: 1.2em;
    margin-bottom: 1em;
    letter-spacing: 0;
}

h1 {
    font-size: $baseHeaderFontSize;
    line-height: $baseHeaderFontLineHeight;
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    &.heading {
        background-image: url(../images/star.png);
        background-repeat: no-repeat;
        background-position:  2px 16px;
        padding-left: 138px;
        width: 530px;
        min-height: 90px;
        line-height: 120px;
        vertical-align: middle;
        span {
            vertical-align: middle;
            display: inline-block;
            line-height: $baseHeaderFontLineHeight;
        }

        @media screen and (max-width: $screen-xs-max) {
            background-size: 70px 70px;
            padding-left: 105px;
            line-height: 100px;
            letter-spacing: 0.05em;
            width: auto;
            span {
                font-size: 45px;
                line-height: 45px;
            }
        }
        @media screen and (max-width: $screen-xs) {
            padding-left: 75px;
            width: auto;
            span {
                font-size: 34px;
                line-height: 34px;
            }
        }
    }
}

h2 {
    margin-bottom: 0.5em;
    font-size: 1.575em;
    line-height: 1.175em;
}
